import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet.tsx";
import { Button } from "@/components/ui/button.tsx";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import menuItems from "@/menu-items.ts";
import { useState } from "react";

export default function HamburgerMenu() {
  const [open, setOpen] = useState(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <Button
        onClick={() => setOpen(true)}
        variant="ghost"
        size="icon"
        aria-label="Menu"
      >
        <HamburgerMenuIcon className="h-[1.2rem] w-[1.2rem]" />
      </Button>
      <SheetContent side="left">
        <SheetHeader>
          <SheetTitle>
            <a href="/" className="text-2xl font-extrabold">
              fiszer.dev
            </a>
          </SheetTitle>
          <div className="flex flex-col gap-4">
            {Object.values(menuItems).map(({ title, href }) => (
              <a
                key={href}
                href={href}
                onClick={() => setOpen(false)}
                className="text-xl first:mt-8"
              >
                {title}
              </a>
            ))}
          </div>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}
